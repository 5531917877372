import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
const App = lazy(() => import("./app"));

window.orgData = {
  APP_ORG: process.env.APP_ORG,
  TITLE_NAME: process.env.TITLE_NAME
};

const appElem = document.getElementById('app');

if (appElem) createRoot(appElem).render(<App />);
else {
  const elem = document.createElement('div');
  document.body.appendChild(elem);

  createRoot(elem).render(<App />);
}